

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import RobotBoard from "@/components/RobotBoard.vue";
import TipDialog from "@/components/TipDialog.vue";
import UpdateDialog from "@/components/UpdateDialog.vue";
import {setPushTags} from "@/utils/appUtil";

// import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller/dist/vue-virtual-scroller.umd.js';
//node_modules/vue-virtual-scroller/dist/vue-virtual-scroller.esm.js 
import {
  getRobotList,
  GetRobotListReq,
  Robot,
  unbindRobot,
  UnBindRobotReq,
  updateRobot,
  UpdateRobotReq,
} from "@/api/robot";
import { CommonListRes, CommonRes, Page } from "@/api/common";
import { Toast } from "vant";
import { jsonParse } from "@/utils/utils";
import { User } from "@/api/user";

interface textStatus {
  isSelect: boolean;
  text: string;
}
@Component({
  components: {
    RobotBoard,
    TipDialog,
    UpdateDialog,
    RecycleScroller
  },
})
export default class Index extends Vue {
  private searchText: string = "";
  private robotList: Robot[] = [];
  private robotListShow: Robot[] = []; // 全部数据
  private observer: any = null;
  private robotListAll: Robot[] = [];
  private show: boolean = false;
  private updateshow: boolean = false;
  private curRobotName: string = "";
  private curSeriesNumber: string = "";
  private curPage: number = 1;
  private limit: number = 1000;
  private filtershow: boolean = false;
  private refreshing: boolean = false;
  private loading: boolean = false;
  private finished: boolean = false;
  private activeSwiper: number = -1;
  private scroll: number = 0;
  private isPause: boolean = false;
  private hiText: string = "";
  private rlength: number = 0;
  private fitleShow: boolean = false;
  private zwFlag:boolean = true;
  
  private items: any= [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    // ...
  ]
  private list: textStatus[] = [
    { isSelect: false, text: this.$t("working").toString() },
    { isSelect: false, text: this.$t("online").toString() },
    { isSelect: false, text: this.$t("sleep").toString() },
    { isSelect: false, text: this.$t("outline").toString() },
    { isSelect: false, text: this.$t("error").toString() }
  ]
  created() {
    console.log("robot created");
    let refresh = localStorage.getItem("refresh");
    if (refresh != null) {
      localStorage.removeItem("refresh");
    }
  }

  mounted() {
    console.log('Component robot mounted');
    window.addEventListener("scroll", this.handleScroll, true);
    this.refreshing = true;
    //this.initObserver();
    //this.onRefresh();
    // this.onLoad();
  }

  destroyed() {
    this.isPause = false;
    console.log('Component robot destroyed', this.isPause );
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.isPause) {
      const $content = document.querySelector(".robotBox");
      const scrollTop = $content ? $content.scrollTop : 0;
      this.scroll = scrollTop;
    }
  }

  activated() {
    console.log('Component robot activated: ', this.isPause);
    this.isPause = false;
    let refresh = localStorage.getItem("refresh");
    console.log("activated refresh AAA:" + refresh);
    if (refresh != null || this.refreshing) {
      let jsUserInfo = localStorage.getItem("userInfo")
      if(jsUserInfo != null) {
        let userInfo: User = JSON.parse(jsUserInfo);
        this.hiText = "Hi," +userInfo.username;
      }
      this.robotList = [];
      this.robotListAll = [];
      localStorage.removeItem("refresh");
      this.refreshing = true;
      this.onRefresh();
    } else {
      const $content = document.querySelector(".robotBox");
      if (this.scroll && $content) {
         $content.scrollTop = this.scroll;
      }
    }
  }
  private initObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.loadMoreData();
            }
          });
        },
        { rootMargin: '0px', threshold: 1.0 }
      );

      // 监听最后一个元素
      this.$nextTick(() => {
        const lastItem = this.$refs.lastItem;
        if (lastItem) {
          this.observer.observe(lastItem);
        }
      });
  }
  private loadMoreData () {
      // 假设每次加载20条数据
      const additionalItems = this.robotList.slice(
        this.robotListShow.length,
        this.robotListShow.length + 20
      );
      this.robotListShow.push(...additionalItems);

      // 更新观察目标为新的最后一个元素
      this.$nextTick(() => {
      const lastItem = this.$refs.lastItem;
      if (lastItem) {
        this.observer.observe(lastItem);
      }
    });
  }
  deactivated() {
    this.isPause = true;
    console.log('Component robot deactivated');
  }
  private selectItem(item: textStatus, index: number) {
    if (item.isSelect) {
      item.isSelect = false;
    } else {
      this.list.forEach(element => {
        if (element === item) {
          element.isSelect = true;
        } else {
          element.isSelect = false;
        }
      });
    }
  }
  private onRefresh() {
    // this.refreshing = true;
    console.log("activated refresh BBBB");
    this.activeSwiper = -1;
    this.finished = false;
    this.curPage = 1;
    this.loading = true;
    this.onLoad();
  }

  private onLoad() {
    console.log("onLoad");
    console.log("activated refresh CCCCC");
    this.getRobotList();
  }
  private openFilter(){
    console.log("openFilter");
    this.fitleShow = true;
  }

  private async getRobotList() {
    let req: GetRobotListReq = {
      page: this.curPage,
      limit: this.limit,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    if (res.code == 0) {
      if (this.refreshing) {
        this.robotListAll = [];
        this.refreshing = false;
      }
      this.robotListAll = res.page.list;//this.robotListAll.concat(res.page.list);
      let tags = []
      let tempRobotList: Robot[] = [];
      if (this.robotListAll.length > 0) {
        for (let index = 0; index < this.robotListAll.length; index++) {
          const element = this.robotListAll[index];
          if (element.robotEntity.stateText) {
            let json = await jsonParse("" + element.robotEntity.stateText).catch(() => {
              this.loading = false;
              this.finished = true;
            });
            element.robotStat = json;
          }
          element.robotId = element.robotEntity.robotId;
          if (element.robotEntity.routerText) {
            let routerInfo = JSON.parse(element.robotEntity.routerText)
            element.routerInfo = routerInfo;
          }
          if (element.robotEntity) {
            console.log('this.$i18n.locale', this.$i18n.locale);
            let lang = '';
            if(this.$i18n.locale.includes('zh')) {
              lang = '_zh';
            }
            tags.push(element.robotEntity.robotId + lang);
            if (element.robotEntity) {
              if(this.list[0].isSelect) {//工作中
                if (element.robotStat && element.robotStat.robot_state  == 2) {
                  tempRobotList.push(element)
                }
              } else if(this.list[1].isSelect) {//在线
                if (element.robotStat && element.robotStat.robot_state > -1) {
                  tempRobotList.push(element)
                } else if (element.routerInfo && element.routerInfo.router_state >= 0  && !element.routerInfo.is_robot_on) {
                  tempRobotList.push(element)
                }
              } else if (this.list[2].isSelect) { //休眠
                if (element.routerInfo && element.routerInfo.router_state >= 0  && !element.routerInfo.is_robot_on) {
                  tempRobotList.push(element)
                }
                // if (element.robotStat && element.robotStat.robot_state == 11) {
                //   tempRobotList.push(element)
                // }
              }  else if (this.list[3].isSelect) { //离线
                if (element == null || element.robotStat == null || element.robotStat.robot_state == -1) {
                  tempRobotList.push(element)
                }
              } else if(this.list[4].isSelect) { // 异常
                if (element.robotStat && element.robotStat.error_code && element.robotStat.error_code.length > 0 && element.robotStat.robot_state > -1) {
                  tempRobotList.push(element)
                }
              } else {
                tempRobotList.push(element)
              }
            }
          }
          // console.log('tags', tags)
        }
      }
      setPushTags(tags);
      this.robotListAll = [];
      this.robotListAll = tempRobotList;
      console.log('robotListAll', this.robotListAll)
      if (this.searchText != "") {
        this.checkSearchText();
      } else {
        this.robotList = this.robotListAll.slice(
        0,
        40
      )
        // this.initObserver();
      }
      if (res.page.totalPage <= this.curPage) {
        this.finished = true;
      } else {
        this.curPage++;
      }
    } else {
      this.loading = false;
      this.finished = true;
    }
  }
  private goToAddRobot() {
    this.$router.push({
      path: "/addRobot",
    });
  }
   private compositionstart() {
    this.zwFlag = false;
    console.log("compositionstart")
  }
   private compositionend() {
    this.zwFlag = true
    console.log("compositionend")
  }
  private checkSearchText() {
    console.log(" searchText:" + this.searchText)
    if (this.zwFlag) {
      if (this.searchText != "") {
        this.rlength = 0;
        this.robotList = this.filterList(this.robotListAll, this.searchText);
       // this.initObserver();
      } else {
        this.onRefresh();
      }
    }
  }

  private clickItemEvent(robotId: string, name: string) {
    this.isPause = true
    this.$router.push({
      path: "/robotDetail",
      name: "RobotDetail",
      params: {
        robotName: name,
        robotId: robotId,
      },
    });
  }

  private deleteItem(item: Robot) {
    console.log("deleteItemEvent robotId: " + item.robotEntity.robotId);
    this.curSeriesNumber = item.robotEntity.robotId;
    this.show = true;
  }

  private updateItem(item: Robot) {
    console.log("updateItemEvent index: " + item.robotEntity.name);
    this.curRobotName = item.robotEntity.name;
    this.curSeriesNumber = item.robotEntity.robotId;
    this.updateshow = true;
  }

  private setActiveSwiperEvent(index: number) {
    this.activeSwiper = index;
  }

  private async confirmEvent() {
    let req: UnBindRobotReq = {
      robotId: this.curSeriesNumber,
    };
    console.log("confirmEvent seriesNumber:" + this.curSeriesNumber);
    let res: CommonRes = await unbindRobot(req);
    if (res.code == 0) {
      this.show = false;
      this.curSeriesNumber = "";
      this.onRefresh();
    }
  }

  private cancelEvent() {
    this.show = false;
  }

  private async confirmUpdateEvent(robotName: string, robotId1: string) {
    robotName = robotName.trim();
    if (robotName == "") {
      Toast(this.$t("robotNameNull").toString());
      return;
    }
    let req: UpdateRobotReq = {
      name: robotName,
      robotId: robotId1,
    };
    console.log(
      "confirmEvent robotName:%s, robotId:%s",
      robotName,
      robotId1
    );
    let res: CommonRes = await updateRobot(req);
    if (res.code == 0) {
      this.updateshow = false;
      this.onRefresh();
    }
  }

  private cancelUpdateEvent() {
    this.updateshow = false;
}

  private clearSearchText() {
    this.searchText = "";
    this.onRefresh();
  }

  private startFilter() {
    this.filtershow = true;
  }

  private onClose() {
    this.checkSearchText();
  }
  private clickConfirm () {
    this.fitleShow = false;
    console.log('clickConfirm')
    // this.refreshing = true;
    this.refreshing = true;
    this.onRefresh();
    // this.onLoad();
  }
  private clickReset() {
    this.fitleShow = false;
  }
  private filterList(list: Robot[], text: string) {
    console.log('filterList:', list)
    return list.filter((item: Robot) => this.check(item, text));
  }

  private check(robot: Robot, text: string) {
    if (
      (robot.robotEntity.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
      robot.robotEntity.robotId.toLowerCase().indexOf(text.toLowerCase()) > -1) &&
      this.rlength < 20
    ) {
      console.log('checkRobot:', robot)
      this.rlength++;
      return true;
    } else {
      return false;
    }
  }
}
