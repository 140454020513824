/*
* @Author: Administrator
* @Date:   2018-08-04 10:51:09
* @Last Modified by:   Administrator
* @Last Modified time: 2018-10-09 14:30:12
*/
// eslint-disable-next-line prefer-const
// let globalData = {
//   firstRoute:"",
//   url: "/api",
//   domainUrl: "https://api.movexbot.com/api/v1",//正式
//   picUrl: "https://api.movexbot.com/api/v1/file/pictures/",//正式
//   lan: "en_US", //中文 zh_CN  英文 en_US
//   mapUrl: "https://api.movexbot.com/api/v1/file/robot/", //地图
//   fileUrl: "/Map/",
//   exportFileUrl: "https://api.movexbot.com/api/v1/file/",
//   yjhUrl: "h5.cleanrobotm.yijiahe.com",
//   tcurl: "tc.movexbot.com"
//  };
// const globalData = {
//   firstRoute:"",
//   url: "/api",
//   domainUrl: "http://192.168.0.31:8080/api/v1",//正式
//   picUrl: "http://192.168.0.31:8080/api/v1/file/pictures/",//正式
//   lan: "zh_CN", //中文 zh_CN  英文 en_US
//   mapUrl: "http://192.168.0.31:8080/api/v1/file/robot/", //地图
//   fileUrl: "/Map/",
//   exportFileUrl: "http://192.168.0.31:8080/api/v1/file/",
//   yjhUrl: "192.168.0.17:8888"
// // };
// const globalData = {
//   firstRoute:"",
//   url: "/api",
//   domainUrl: "https://test.movexbot.com:8087/api/v1",//正式
//   picUrl: "https://test.movexbot.com:8087/api/v1/file/pictures/",//正式
//   lan: "zh_CN", //中文 zh_CN  英文 en_US
//   mapUrl: "https://test.movexbot.com:8087/api/v1/file/robot/", //地图
//   fileUrl: "/Map/",
//   exportFileUrl: "https://test.movexbot.com:8087/api/v1/file/",   
//   yjhUrl: "192.168.0.34:8888",
//   tcurl: "192.168.0.50:8889"
// };
// 基础域名配置
// 定义环境类型
type EnvType = 'test' | 'prod';

// 定义配置接口
interface EnvConfig {
  domain: string;
  yjhUrl: string;
  tcurl: string;
}

// 定义环境配置映射
const ENV_CONFIG: Record<EnvType, EnvConfig> = {
  test: {
    domain: 'https://test.movexbot.com:8087',
    yjhUrl: '192.168.0.34:8888',
    tcurl: '192.168.0.50:8889'
  },
  prod: {
    domain: 'https://api.movexbot.com', // 假设这是生产环境域名
    yjhUrl: 'h5.cleanrobotm.yijiahe.com',  // 替换为实际的生产环境地址
    tcurl: 'tc.movexbot.com'     // 替换为实际的生产环境地址
  }
};

// 获取当前环境
const getEnv = (): EnvType => {
  // 从命令行参数获取环境配置
  if (process.env.VUE_APP_ENV === 'test' || process.env.VUE_APP_ENV === 'prod') {
    return process.env.VUE_APP_ENV;
  }
  
  // 从域名判断环境
  if (window.location.hostname.includes('test.')) {
    return 'test';
  }
  
  return 'prod'; // 默认返回生产环境
};

// 获取当前环境的配置
const currentEnv: EnvType = getEnv();
const currentConfig = ENV_CONFIG[currentEnv];

// 定义全局配置接口
interface GlobalData {
  firstRoute: string;
  url: string;
  domainUrl: string;
  picUrl: string;
  lan: string;
  mapUrl: string;
  fileUrl: string;
  exportFileUrl: string;
  yjhUrl: string;
  tcurl: string;
}

// 导出全局配置
const globalData: GlobalData = {
  firstRoute: "",
  url: "/api",
  domainUrl: `${currentConfig.domain}/api/v1`,
  picUrl: `${currentConfig.domain}/api/v1/file/pictures/`,
  lan: "zh_CN",
  mapUrl: `${currentConfig.domain}/api/v1/file/robot/`,
  fileUrl: "/Map/",
  exportFileUrl: `${currentConfig.domain}/api/v1/file/`,
  yjhUrl: currentConfig.yjhUrl,
  tcurl: currentConfig.tcurl
};

export default globalData;

// 导出环境判断函数
export const isTestEnv = (): boolean => currentEnv === 'test';
export const isProdEnv = (): boolean => currentEnv === 'prod';
export const getCurrentEnv = (): EnvType => currentEnv;
export {
    globalData
};      