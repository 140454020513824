
























































import { Toast } from "vant";
import { globalData } from '@/utils/config';
import { Component, Vue } from "vue-property-decorator";
import { login, LoginReq, LoginRes, register, RegisterReq } from "@/api/user";
import { getPushId } from "@/utils/appUtil";

@Component({
  components: {},
})
export default class Index extends Vue {
  private req: LoginReq = {
    account: "",
    password: "",
    languageCode: navigator.language.toLowerCase(),
    pushId: getPushId()
  };
  private checked: boolean = true;
  private logoSrc: string = ""
  private reqAble: boolean = false;
  private logowidth: number = 154
  private logoheight: number = 38
  private loading: boolean = false;
  private errorNum: number = 0;
  created() {
    if (location.host == globalData.yjhUrl) {
        this.logoSrc = require('@/assets/img/yjh/logo.png')
        localStorage.setItem('yjhType', "-1")
        this.logowidth = 280
        this.logoheight = 100
    } else if (location.host == globalData.tcurl) {
        this.logoSrc = require('@/assets/img/tc45/tc45_logo.png')
        // localStorage.setItem('yjhType', "-1")
        this.logowidth = 280
        this.logoheight = 64
    } else {
      localStorage.setItem('yjhType', "1")
      this.logoSrc = require('@/assets/img/MoveXBot.png')
      this.logowidth = 154
      this.logoheight = 38
    }
    console.log("login created host" + location.host);
    // this.autoChange();
    this.initSave();
    let account = localStorage.getItem("account");
    if (account != null) {
      this.req.account = account;
    }
    let password = localStorage.getItem("password");
    if (password != null) {
      this.req.password = password;
    }
    let savePassword = localStorage.getItem("savePassword");
    if (savePassword != null) {
      this.checked = true;
    }
    this.checkReq();
  }

 
  autoChange() {
    console.log("navigator.language", navigator.language);
    alert("kkk")
    // this.$i18n.locale = "en_US";
   // alert(navigator.language+"kkk")
   let lang = navigator.language.toLowerCase()
   if(lang.indexOf('en') !=-1){
      this.$i18n.locale = "en_US";
   } else if(lang.indexOf('zh') !=-1 || lang.indexOf('cn') !=-1){
      this.$i18n.locale = "zh_CN";
   } else {
      this.$i18n.locale = "en_US";
   }
    // switch (navigator.language) {
    //   case "en":
    //   case "en-US":
    //   case "en-us":
    //     this.$i18n.locale = "en_US";
    //     break;
    //   case "zh-CN":
    //   case "zh-cn":
    //   case "zh":
    //     this.$i18n.locale = "zh_CN";
    //     break;
    //   default:
    //     this.$i18n.locale = "en_US";
    //     break
    // }
  }

  /**
   * 执行登录
   */
  
  private async confirmLogin() {
    if(this.errorNum > 15) {
      return
    } 
    if(this.errorNum == 5) {
      this.errorNum ++
      Toast(this.$t('paw_too_error').toString())
      return
    } 
    if(this.errorNum > 8) {
      Toast(this.$t('paw_too_error').toString())
      this.errorNum++
      return
    } 
    this.loading = true;
    let res: LoginRes = await login(this.req);
    console.log("confirmLogin", res);
    if (res.code == 0) {
      localStorage.setItem("account", this.req.account);
      if (this.checked) {
        localStorage.setItem("password", this.req.password);
      } else {
        localStorage.removeItem("password");
      }
      if(res.user.userType === -1) {
          this.loading = false;
         Toast(this.$t("account_is_not_activated"))
        return
      }
      localStorage.setItem("id", res.user.id + "")
      localStorage.setItem("userInfo", JSON.stringify(res.user));
      localStorage.setItem("loginTime", String(res.loginTime));
      localStorage.setItem("token", res.token);
      localStorage.setItem("refresh", "true");
      this.loading = false;
      this.$router.replace({
        path: "/",//robot
      });
    } else {
      this.errorNum++
      this.loading = false;
    }
  }

  private forgetPassword() {
    this.$router.push({
      path: "/forgetPassword",
    });
  }

  // private changeRememberPassword() {
  //   this.checked = !this.checked;
  //   if (this.checked) {
  //     localStorage.setItem("savePassword", "1");
  //   } else {
  //     localStorage.removeItem("savePassword");
  //   }
  // }

  private initSave() {
    localStorage.setItem("savePassword", "1");
  }

  private goToRegister() {
    this.$router.push({
      path: "/register",
    });
  }

  /**
   *判断输入框是否为空
   */
  private checkReq() {
    if (this.req.account != "" && this.req.password != "") {
      this.reqAble = true;
    } else {
      this.reqAble = false;
    }
  }

  private toGoUrl() {
    const url = "https://beian.miit.gov.cn/";
    window.location.href = url;
  }
}
