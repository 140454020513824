






















import { Component, Vue } from "vue-property-decorator";
import {
  sendSMS,
  SendSMSReq,
  updatePasswordByEmail,
  updatePasswordByEmailReq,
} from "@/api/user";
import { CommonRes } from "@/api/common";
import { setEmailNt, IsPushMessageEntity } from "@/api/message";
import { Toast } from "vant";
import { checkPassword } from "@/utils/utils";
import SendButton from "@/components/SendButton.vue";
import { User } from "@/api/user";
@Component({
  components: {
    SendButton,
  },
})
export default class Index extends Vue {
  private req: updatePasswordByEmailReq = {
    email: "",
    password: "",
    code: "",
  };
  private newPassword: string = "";
  private confirmPassword: string = "";
  private userInfo: any;
  private pushMessageEt: IsPushMessageEntity = {
    isPushFalut: false,
    isPushTask: false
  };
  created () {
     let jsUserInfo = localStorage.getItem("userInfo")
    console.log("email created：" + jsUserInfo);
    if(jsUserInfo != null) {
      let userInfo1: User = JSON.parse(jsUserInfo);
      console.log("email pushMessage" + userInfo1.pushMessage);
      if(userInfo1.pushMessage != null && userInfo1.pushMessage.length > 0) {
        this.pushMessageEt = JSON.parse(userInfo1.pushMessage)
      }
      this.userInfo = userInfo1
    }
  }

 private async pushMsgChange () {
    console.log("pushMessageEt", this.pushMessageEt)
    let res: CommonRes = await setEmailNt(this.pushMessageEt);
      console.log("res", res);
      if (res.code == 0) {
        // Toast(this.$t("dealOK").toString())
        this.userInfo.pushMessage = JSON.stringify(this.pushMessageEt)
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        console.log(res.msg);
      }
 }

  private back() {
    this.$router.go(-1);
  }

  /**
   * 执行找回密码
   */
  private async confirmFindPassword() {
    if (!checkPassword(this.newPassword)) {
      Toast(this.$t("passwordError").toString());
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      Toast(this.$t("passwordDiffer").toString());
      return;
    }
    this.req.email = this.req.email.trim();
    if (this.req.email.length == 0) {
      Toast(this.$t("usernameShort").toString());
      return;
    }
    if (this.req.code.length == 0) {
      Toast(this.$t("codeShort").toString());
      return;
    }
    this.req.password = this.newPassword;
    let res: CommonRes = await updatePasswordByEmail(this.req);
    console.log("updatePasswordByEmail", res);
    if (res.code == 0) {
      let savePassword = localStorage.getItem("savePassword");
      if (savePassword != null) {
        localStorage.setItem("password", this.req.password);
      }
      Toast(this.$t("updatePasswordOk").toString());
      this.$router.go(-1);
    }
  }
}
